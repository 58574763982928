//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export interface IGiveawayItemDto
{
	id: string;
	exchangeId: string | null;
	steamUserId64: string;
	inspectInGameLink: string;
	icon: string;
	exterior: string;
	rarity: string;
	marketHashName: string;
	skinShortName: string;
	category: string;
	subcategory: string;
	weapon: string;
	collection: string;
	collectionImage: string;
	isStatTrak: boolean;
	price: number;
	purchasePrice: number | null;
	markedAsInTrade: boolean;
	tradableAfter: string | null;
	reservedTo: string | null;
	assetId: number | null;
	botSteamId64: string;
	float: number | null;
	stickers: IStickerDto[];
}
export interface IKeyChainDto
{
	name: string;
	imageUrl: string;
	slot: number;
}
export interface IStickerDto
{
	name: string;
	imageUrl: string;
	slot: number;
}
export interface IInventoryDetailsDto
{
	inventoryCapacity: number;
	inventoryTradableItemsValue: number;
}
export interface IInventoryItemDto
{
	assetId: string;
	id: string;
	instanceId: string;
	inspectInGameLink: string;
	icon: string;
	exterior: string;
	classId: string;
	rarity: string;
	marketHashName: string;
	skinShortName: string;
	category: string;
	subcategory: string;
	weapon: string;
	stickers: IStickerDto[];
	collection: string;
	collectionImage: string;
	isTradable: boolean;
	nonTradableReason: NonTradableReason | null;
	isStatTrak: boolean;
	price: number | null;
	instantSellPrice: number | null;
	instantSellBonusPrice: number | null;
	pattern: number | null;
	paintIndex: number | null;
	tradableAfter: string | null;
	priceWithoutEventBonus: number | null;
	priceWithoutEventBonusInstantSell: number | null;
	maxDepositCount: number | null;
	keyChains: IKeyChainDto[];
}
export interface IUserInventoryDto
{
	inventoryDetails: IInventoryDetailsDto;
	inventoryItems: any;
}
export enum NonTradableReason {
	TradeLock = 0,
	PriceMissing = 1,
	PriceNonStable = 2,
	OverStock = 3,
	NotWantedItem = 4
}
export interface IStickerFilterItemDto
{
	id: string;
	name: string;
	shortName: string;
	type: string[];
	description: string;
	rarity: string;
	image: string;
	price: number | null;
}
export interface IAutocompleteItemDto
{
	marketHashName: string;
	icon: string;
	marketNameHashCodes: number[];
	score: number | null;
}
export interface INotSupportedItemDto
{
	id: string;
	marketHashName: string;
	reason: string;
}
export interface IExchangerInventoryItemsGroupDto
{
	marketHashName: string;
	marketNameHashCode: number;
	skinShortName: string;
	rarity: string;
	exterior: string;
	category: string;
	weapon: string;
	subcategory: string;
	collection: string;
	collectionImage: string;
	nameColor: string;
	bgColor: string;
	itemType: string;
	pattern: number | null;
	paintIndex: number | null;
	isStatTrak: boolean;
	phase: ItemPhases | null;
	fadePercentage: number | null;
	itemsCount: number;
	lowestPrice: number;
	icon: string;
	lowestPriceWithoutDiscount: number | null;
	singleItem: IExchangerInventoryItemDto | null;
}
export interface IExchangerInventoryItemsGroupedItemDto
{
	id: string;
	inspectInGameLink: string;
	icon: string;
	stickers: IStickerDto[];
	float: number | null;
	price: number | null;
	tradableAfter: string | null;
	priceWithoutDiscount: number | null;
}
export interface IAdminPortalExchangerInventoryItemDto
{
	id: string;
	inspectInGameLink: string;
	icon: string;
	exterior: string;
	rarity: string;
	marketHashName: string;
	marketNameHashCode: number;
	skinShortName: string;
	category: string;
	weapon: string;
	subcategory: string;
	stickers: IStickerDto[];
	collection: string;
	collectionImage: string;
	nameColor: string;
	bgColor: string;
	itemType: string;
	pattern: number | null;
	paintIndex: number | null;
	float: number | null;
	price: number | null;
	isStatTrak: boolean;
	tradableAfter: string | null;
	phase: ItemPhases | null;
	fadePercentage: number | null;
	priceWithoutDiscount: number | null;
	isExternalMarketItem: boolean;
	keyChains: IKeyChainDto[];
}
export interface IExchangerInventoryItemDto
{
	id: string;
	inspectInGameLink: string;
	icon: string;
	exterior: string;
	rarity: string;
	marketHashName: string;
	marketNameHashCode: number;
	skinShortName: string;
	category: string;
	weapon: string;
	subcategory: string;
	stickers: IStickerDto[];
	collection: string;
	collectionImage: string;
	nameColor: string;
	bgColor: string;
	itemType: string;
	pattern: number | null;
	paintIndex: number | null;
	float: number | null;
	price: number | null;
	isStatTrak: boolean;
	tradableAfter: string | null;
	phase: ItemPhases | null;
	fadePercentage: number | null;
	priceWithoutDiscount: number | null;
	keyChains: IKeyChainDto[];
}
export interface IEditConfigurationCommand
{
	name: string;
	newValue: string;
}
export interface ICollectionItemDto
{
	name: string;
	image: string;
}
export enum DomainError {
	Unknown = 0,
	InvalidPattern = 1
}
export enum DemotionReason {
	Default = 0
}
export enum DiscountReason {
	Default = 0,
	BlackWeek = 1,
	Spring = 2
}
export enum ItemColor {
	Undefined = 0,
	Red = 1,
	Blue = 2,
	Yellow = 3,
	Green = 4,
	Violet = 5,
	Gray = 6,
	Brown = 7,
	Black = 8,
	White = 9
}
export enum ItemPhases {
	BlackPearl = 0,
	Emerald = 1,
	Phase1 = 2,
	Phase2 = 3,
	Phase3 = 4,
	Phase4 = 5,
	Ruby = 6,
	Sapphire = 7
}
export enum OtherInventoryEnum {
	StatTrak = 0,
	Stickers = 1,
	Souvenir = 2,
	TagWithName = 3
}
export enum PromotionReason {
	Default = 0,
	BlackWeek = 1
}
export enum RareItemInventoryEnum {
	RareFloat = 0,
	RareStickers = 1,
	RarePattern = 2
}
export enum RefreshInventoryResult {
	Unknown = 0,
	Success = 1,
	PrivateInventory = 2,
	InternalError = 3
}
export enum UnavailabilityReasons {
	Overstock = 0,
	NotWantedItem = 1
}
export enum TagConstants {
	Exterior = 0,
	Rarity = 1,
	Type = 2,
	Weapon = 3,
	ItemSet = 4
}
export enum SystemAlertType {
	Anomaly = 0,
	Exception = 1,
	JobFailed = 2
}
export enum SlackActionId {
	BlockUser = 0,
	AcceptPausedExchange = 1,
	CancelPausedExchange = 2
}
export enum ServiceHealthState {
	Unknown = 0,
	Ok = 1,
	Bad = 2,
	Warning = 3
}
export enum CType {
	String = 0,
	Int = 1,
	Bool = 2,
	Decimal = 3,
	Json = 4,
	DateTime = 5
}
