import { useTranslation } from 'next-i18next';
import CoinsIcon from 'public/icons/coins-fill.svg';
import { useAuth } from 'react-oidc-context';
import { HideOnBiggerThan, HideOnSmallerThan } from '@/components/HideOn';
import { useGetSteamProfile } from '@/hooks/query/useGetSteamProfile';
import { useGetUserConfigs } from '@/hooks/query/useGetUserConfigs';
import { modalsActor } from '@/machines';
import { GetBonusButtonMobileWrapper, GetBonusButtonWrapper, GetBonusIconButton, GetBonusText } from './GetBonusButton.styles';
export const GetBonusButton = () => {
  const {
    t
  } = useTranslation('header');
  const {
    isAuthenticated,
    signinRedirect
  } = useAuth();
  const {
    data: steamProfile
  } = useGetSteamProfile();
  const {
    data: userConfigs
  } = useGetUserConfigs({
    steamId64: steamProfile?.steamId64
  });
  const handleClick = () => isAuthenticated ? modalsActor.send({
    type: 'OPEN_MODAL',
    modalId: 'springDiscountsModal'
  }) : signinRedirect();
  if (isAuthenticated && (!userConfigs || userConfigs.bonus.userBonusLevel && userConfigs.bonus.userBonusLevel > 3)) {
    return null;
  }
  return <>
      <HideOnBiggerThan breakpoint="xxl" data-sentry-element="HideOnBiggerThan" data-sentry-source-file="GetBonusButton.tsx">
        <GetBonusButtonMobileWrapper onClick={handleClick} title={t('getBonus.long')} data-sentry-element="GetBonusButtonMobileWrapper" data-sentry-source-file="GetBonusButton.tsx">
          <GetBonusIconButton variant="green-fill" size="sm" data-sentry-element="GetBonusIconButton" data-sentry-source-file="GetBonusButton.tsx">
            <CoinsIcon data-sentry-element="CoinsIcon" data-sentry-source-file="GetBonusButton.tsx" />
          </GetBonusIconButton>
          <GetBonusText data-sentry-element="GetBonusText" data-sentry-source-file="GetBonusButton.tsx">{t('getBonus.short')}</GetBonusText>
        </GetBonusButtonMobileWrapper>
      </HideOnBiggerThan>
      <HideOnSmallerThan breakpoint="xxl" style={{
      height: '100%'
    }} data-sentry-element="HideOnSmallerThan" data-sentry-source-file="GetBonusButton.tsx">
        <GetBonusButtonWrapper onClick={handleClick} data-sentry-element="GetBonusButtonWrapper" data-sentry-source-file="GetBonusButton.tsx">
          <CoinsIcon data-sentry-element="CoinsIcon" data-sentry-source-file="GetBonusButton.tsx" />
          {t('getBonus.long')}
        </GetBonusButtonWrapper>
      </HideOnSmallerThan>
    </>;
};