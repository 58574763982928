import { assign, createMachine } from 'xstate'

const modals = [
  'mobileFilters',
  'notificationsDropdown',
  'userMenuDrawer',
  'blackWeekModal',
  'tradeUrlModal',
  'getBonusModal',
  'springDiscountsModal',
] as const

export type ModalId = (typeof modals)[number]

type ModalsState = 'opened' | 'closed'

interface ModalsStateSchema {
  modals: {
    [key in ModalId]: ModalsState
  }
}

type OpenModalEvent = { type: 'OPEN_MODAL'; modalId: ModalId }
type CloseModalEvent = { type: 'CLOSE_MODAL'; modalId: ModalId }
type ToggleModalEvent = { type: 'TOGGLE_MODAL'; modalId: ModalId }

type ModalEvent = OpenModalEvent | CloseModalEvent | ToggleModalEvent

export const modalsMachine = createMachine({
  id: 'modal',
  initial: 'idle',
  types: {} as {
    context: ModalsStateSchema
    events: ModalEvent
  },
  context: {
    modals: modals.reduce(
      (acc, modalId) => ({ ...acc, [modalId]: 'closed' }),
      {}
    ) as ModalsStateSchema['modals'],
  },
  states: {
    idle: {
      on: {
        OPEN_MODAL: {
          actions: assign({
            modals: ({ context, event }) => ({
              ...context.modals,
              [event.modalId]: 'opened' as ModalsState,
            }),
          }),
        },
        CLOSE_MODAL: {
          actions: assign({
            modals: ({ context, event }) => ({
              ...context.modals,
              [event.modalId]: 'closed' as ModalsState,
            }),
          }),
        },
        TOGGLE_MODAL: {
          actions: assign({
            modals: ({ context, event }) => ({
              ...context.modals,
              [event.modalId]: (context.modals[event.modalId] === 'opened'
                ? 'closed'
                : 'opened') as ModalsState,
            }),
          }),
        },
      },
    },
  },
})
